export const why_enroll_data = [
    {
      id: 1,
      src: "/bootcamps/carbon_notebook-reference.svg",
      label: "Mentorship",
      content:'Seek guidance from fellow learners, coaches, and industry experts in the community.',
      width: 37,
      height: 30,
    },
    {
      id: 2,
      src: "/bootcamps/Vector.svg",
      label: "Learn from community",
      content:'One can look and analyze the solutions submitted by the other Data Scientists in the community and learn from them.',
      width: 39,
      height: 30,
    },
    {
      id: 3,
      src: "/bootcamps/Robot.svg",
      label: "Challenge yourself",
      content:'There is nothing for you to lose by participating in a challenge. You can fail safe, learn out of the entire experience and bounce back harder.',
      width: 35,
      height: 30,
    },
    {
      id: 4,
      src: "/bootcamps/IdentificationCard.svg",
      label: "Earn recognition",
      content:'You will stand out from the crowd if you do well in AI challenges, it not only helps you shine in the community but also earns rewards.',
      width: 41,
      height: 34,
    },
  ];