import React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";

import Section from "../../../../common/components/layout/utils/Section";
import { useTheme, useMediaQuery } from "@mui/material";
import { why_enroll_data } from "./utils";
import EnrollCard from "./EnrollCard";

const WhyYouShouldEnroll = () => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Section boxProps={{ py: { xs: 6, md: 14 }, bgcolor: "#000000" }}>
      <Box>
        <Typography
          fontWeight={600}
          align="center"
          variant="h3"
          color="#fff"
          sx={{
            pb: { xs: 6, md: 12 },
            [theme.breakpoints.down("md")]: {
              fontSize: 24,
            },
          }}
        >
          Why you should enroll?
        </Typography>
        <Box
          pb={{ xs: 6, md: 1 }}
          display="grid"
          columnGap={2}
          rowGap={3}
          gridTemplateColumns={isDownMd ? "1fr" : "1fr 1fr"}
        >
          {why_enroll_data.map(({ id, label, src, content, width, height }) => (
            <EnrollCard
              key={id}
              id={id}
              label={label}
              src={src}
              content={content}
              width={width}
              height={height}
            />
          ))}
        </Box>
      </Box>
    </Section>
  );
};

export default WhyYouShouldEnroll;
