import React from "react";
import Image from "next/image";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { useTheme, useMediaQuery } from "@mui/material";

import { neutral } from "../../../../common/config/colors";
import { WhyYouShouldEnrollProps } from "./bootcampEnrolltypes";

const EnrollCard: React.FC<WhyYouShouldEnrollProps> = ({
  id,
  src,
  label,
  content,
  width,
  height,
}) => {
  const theme = useTheme();
  const isDownMd = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Box
      key={id}
      display="grid"
      bgcolor="#1F1F1F"
      borderRadius="20px"
      gridTemplateColumns="1fr"
      rowGap={3}
      py={{ xs: 8, md: 12 }}
      px={{ xs: 4, md: 8 }}
      mx={{ xs: 6, md: 4 }}
      my={{ xs: 2, md: 6 }}
    >
      <img src={src} alt={label} width={width} height={height} />
      <Typography variant="h4" fontWeight={600} color={neutral["A500"]}>
        {label}
      </Typography>
      <Typography variant="h6" fontWeight={400} color={neutral["A600"]}>
        {content}
      </Typography>
    </Box>
  );
};

export default EnrollCard;
